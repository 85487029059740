import React from "react";
import { withSubtheme } from "@starberryComponentsMui"
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { LinkItem } from '@myaccount/helper/myaccountLink';
import { MYACCOUNT_DASHBOARD } from "@myaccountUrls";
import Typography from "@mui/material/Typography";
import { StarberryIcons } from '@myaccount/icons';

import defaults from "./defaults";

const NotFound = withSubtheme(props => {
    const {
        className,
        titleProps,
        textProps,
        iconProps,
        titleText,
        subtitleText,
        btnLabel,
    } = props

    return(
        <Container maxWidth="xl">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className={className}>
                        <StarberryIcons {...iconProps} />
                        <Typography classes={{root: "error-title"}} {...titleProps}>{titleText}</Typography>
                        <Typography classes={{root: "error-text"}} {...textProps}>{subtitleText}</Typography>
                        <LinkItem to={MYACCOUNT_DASHBOARD}>{btnLabel}</LinkItem>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}, "404Page", defaults)

export default NotFound
