import React from "react";
import GlobalLayout from "@myaccount/GlobalLayout"
import ErrorPage from "@myaccount/404page";

const NotFoundPage = () => (
    <GlobalLayout>
        <ErrorPage />
    </GlobalLayout>
);

export default React.memo(NotFoundPage);
